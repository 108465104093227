<template>
    <div :key="marketKey" >
      <div class="div_title">
        <context :context=cate />
        <policies/>
      </div>
      <div id="container"></div>
      <div class="card main">
        <p class="agree">1. 개인 간 거래 게시판으로 판매자와의 직거래를 통하여 구매하셔야 됩니다.</p>
        <p class="agree">2. 해당 게시판에 등록된 상품과 내용은 판매자가 등록한 것으로 GEEK9는 등록된 내용에 대하여 일체의 책임을 지지 않습니다.</p>
      </div>
      <Tabs :value="selectedMenu">
        <TabList>
          <Tab value=0>상품검색</Tab>
          <Tab value=1>상품등록</Tab>
        </TabList>
        <TabPanels class="card main" >
          <TabPanel value=0>
            <read page='market'/>
          </TabPanel>
          <TabPanel value=1>
            <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
              <write :page="this.api.getCookies('page')" @login="login" />
            </div>
            <login v-else @login="login" /> 
          </TabPanel>
        </TabPanels>
      </Tabs>
      <div class="ad-container">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="8230887469"
          data-ad-format="horizontal"
          data-full-width-responsive="true">
        </Adsense>
      </div>
    </div>
  </template>
<script>
  
  import { ref, inject } from 'vue'
  import context from '@/components/common/Context.vue'
  import policies from '@/components/common/policies.vue'
  import write from '@/Write.vue'
  import read from '@/Read.vue'
  import ssr from './components/common/ssr'
  import login from '@/components/common/Login.vue'
  
  export default {
    emits: ["setCookies", "setMenu", "login"],
    setup() {
      const setMeta = inject('setMeta')
      const title = 'GEEK9 > 마켓 > 직거래'
      const keywords = '직구,해외직구,구매대행,배송대행,배대지,geek9,국내,중고,온라인,거래,중고거래'
      const description = '국내 중고 상품을 자유롭게 거래하세요.'
      const geek9Url = ssr.GEEK9_URL + 'market'
      setMeta({
        title: title,
        keywords: keywords,
        description: description,
        'og:title': title,
        'og:description': description,
        'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
        'og:url': geek9Url,
        'canonical': geek9Url
      })
      const cate = ref([
        { label: '마켓' },
        { label: '직거래', route: '/market' }
      ])
      const addJsonLd = () => {
        let flag = true
        document.querySelectorAll('script').forEach(el => {
          if (el.getAttribute('type') === 'application/ld+json' ) {
            flag = false
            return
          }
        })
        if(flag === true) {
          const script = document.createElement('script')
          script.setAttribute('type', 'application/ld+json')
          script.textContent = JSON.stringify(
            {
              "@context": "https://schema.org",
              "@graph": [
                ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
              ]
            }
          )
          document.head.appendChild(script)
        }
      }
      return {
        cate, addJsonLd
      }
    },
    components: {
      context, policies, write, read, login
    },
    mounted() {
      this.addJsonLd()
      this.emitter.on('marketReload', this.reload)
      this.api.setPage('market')
      console.log(this.api.getCookies('page'))
    },
    unmounted() {
      this.emitter.off('marketReload', this.reload)
    },
    data() { 
      return { 
        marketName:'',
        marketCountry:'',
        marketDesc:'',
        marketSellPrice:0,
        selectedMenu:'0',
        marketKey: -1,
        marketUseYn:1,
        resultJson:[]
      }
    },
    methods: {
      login() {
        this.$emit('login')
      },
      async click(param) {
        let type = param.type
        if(type === 'SELECT_TRADE') {
          this.emitter.emit('dialog', {flag:true, title:'loading'})
          this.resultJson = []
          this.api.axiosGql('SELECT_TRADE', {type:'SELECT_TRADE', useYn:1})
          .then( async (res) => {
            this.resultJson = res.data
          })
          .finally( () => {
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          })
        }
      },
      reload(flag) {
        if(flag === 'dashboard') {
          this.marketKey++
          this.$emit("setMenu", {page:'market'})
        }
      },
    }
  }
</script>
  
  <style scoped>
  </style>